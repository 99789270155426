import {
  AfterLoad,
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm'

type UserRole = 'Administrator' | 'Editor' | 'Kontributor' | 'Tidak diketahui'

export enum UserRoleEnum {
  Administrator,
  Editor,
  Kontributor,
}

@Entity({
  name: 'users',
})
export class User {
  @PrimaryGeneratedColumn()
  id: number

  @Column({
    type: 'text',
  })
  firstName: string
  @Column({
    type: 'text',
  })
  lastName: string
  @Column({
    type: 'varchar',
    length: 255,
  })
  username: string
  @Column({
    type: 'varchar',
    length: 255,
  })
  email: string
  @Column({
    type: 'varchar',
    length: 255,
  })
  @Column({
    type: 'varchar',
    length: 60,
    select: false,
  })
  password: string
  @Column({
    type: 'varchar',
    length: 255,
  })
  photo: string
  @Column({
    type: 'varchar',
    length: 255,
  })
  phone: string
  @Column({
    type: 'text',
  })
  pob: string
  @Column({
    type: 'date',
  })
  dob: Date
  @Column({
    type: 'int4',
  })
  roleId: UserRoleEnum
  @Column({
    type: 'bool',
  })
  isActive: boolean
  @Column({
    type: 'bool',
  })
  isSSO: boolean
  @Column({
    type: 'varchar',
    length: 255,
  })
  ssoServiceId: string
  @Column({
    type: 'text',
  })
  ssoUserId: string
  @Column({
    type: 'text',
  })
  ssoExtraData: string
  @Column({
    type: 'text',
  })
  subdirectorateName: string

  role: UserRole

  @AfterLoad()
  getRole?() {
    switch (this.roleId) {
      case 0:
        this.role = 'Administrator'
        return
      case 1:
        this.role = 'Editor'
        return
      case 2:
        this.role = 'Kontributor'
        return
      default:
        this.role = 'Tidak diketahui'
        return
    }
  }

  @CreateDateColumn({})
  createdAt: Date
  @UpdateDateColumn({})
  updatedAt: Date
  @DeleteDateColumn({ nullable: true })
  deletedAt: Date
}
